import axios, { type ParamsSerializerOptions } from 'axios'
import dayjs from 'dayjs'
import qs from 'qs'

const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

export const getAttendanceDay = (newDay: dayjs.Dayjs) => {
  // ensure new day is between monday and thursday (included)
  if (newDay.day() < 1) {
    return newDay.subtract(3, 'day') // go from sunday to previous thursday
  } else if (newDay.day() > 4) {
    // -> go to next monday
    // friday = 5 -> + 3
    // saturday = 6 -> + 2
    return newDay.add(8 - newDay.day(), 'day')
  }
  return newDay
}

const isIsoDateString = (value: unknown): value is string => {
  return typeof value === 'string' && ISODateFormat.test(value)
}

export const handleDates = (data: unknown) => {
  if (isIsoDateString(data)) return dayjs(data)
  if (data === null || data === undefined || typeof data !== 'object') return data

  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (isIsoDateString(val)) data[key] = dayjs(val)
    else if (typeof val === 'object') handleDates(val)
  }
  return data
}

axios.interceptors.response.use((rep) => {
  handleDates(rep.data)
  return rep
})

axios.interceptors.request.use((config) => {
  config.paramsSerializer = {
    serialize: (params: Record<string, any>, options?: ParamsSerializerOptions) => {
      return qs.stringify(params, {
        serializeDate: (date: Date) => dayjs(date).format()
      })
    },
    indexes: null // no brackets
  }
  return config
})
