<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useProfileStore } from './stores/profile'
import router from './router'
import { onMounted } from 'vue'
import axios from 'axios'
import type { Ref } from 'vue'
import { ref } from 'vue'
// Ensure loading dates parser in http interception
import './utils/dates'
import dayjs from 'dayjs'
import { useThemeStore } from './stores/themestore'
import { useCookiesStore } from './stores/cookiesstore'
import { useRecaptchaProvider } from 'vue-recaptcha'
import CookiesConsentBanner from '@/components/CookiesConsentBanner.vue'
import UpdateAvailable from './components/UpdateAvailable.vue'

if (import.meta.env.VITE_RECAPTCHA_SITE_KEY_V3) {
  useRecaptchaProvider()
}

const profileStore = useProfileStore()
const cookiesStore = useCookiesStore()

// Ensure them is properly set for the whole application
useThemeStore()

const snackbar: Ref<boolean> = ref(false)
const snackbarText: Ref<string> = ref('')
const version = ref(__APP_VERSION__)
const build_date = ref(__BUILD_DATE__)
const drawer = ref(false)

// On 401, go to login page
// On 403, display snackbar
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    console.log(error?.response?.data)
    if (error.response?.status === 401) {
      profileStore.logout()
      router.push('/login')
    } else if (error.response?.status === 403) {
      if (profileStore.current) {
        // display a Toast indicating it's forbidden
        snackbar.value = true
        snackbarText.value = 'Action non autorisée'
      } else {
        router.push('/login')
      }
    } else {
      return Promise.reject(error)
    }
  }
)

// Get user as soon as possible to properly setup token for server requests
profileStore.getUser()

onMounted(async () => {
  // Handle new domain
  if (window.location.host === "lycee-internat.ddns.net") {
    window.location.href = "https://legta-auzeville.mon-internat.com"
  }
})

const versionClicked = ref(false)
async function forceUpdate() {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    for(let registration of registrations) {
      registration.unregister();
    }
  }
  window.location.reload()
}

</script>

<style>
.v-application.v-theme--light {
  background-color: rgb(237, 237, 237);
}
.footer > label.v-label {
  font-size: 9px;
}
body.recaptcha-hidden .grecaptcha-badge {
  visibility: hidden !important;
}
</style>

<template>
  <v-app>
    <v-app-bar app color="primary" scroll-behavior="hide" v-if="profileStore.current">
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        Gestion internat
      </v-toolbar-title>
      <v-toolbar-items class="hidden-md-and-up">
        <v-btn icon="mdi-reload" @click="forceUpdate()"></v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-if="profileStore.current?.accountValidated" to="/" prepend-icon="mdi-home-city">Batiments</v-btn>
        <v-btn
          v-if="profileStore.current?.accountValidated"
          to="/students"
          prepend-icon="mdi-account-multiple"
        >Etudiants</v-btn>
        <v-btn v-if="profileStore.current?.role == 'admin'" to="/admin/classnames" prepend-icon="mdi-book">Classes</v-btn>
        <v-btn v-if="profileStore.current" to="/profile" prepend-icon="mdi-account-circle">Profil</v-btn>
        <v-btn v-if="profileStore.current?.role == 'admin'" to="/admin/workers" prepend-icon="mdi-cogs">Admin</v-btn>
        <v-divider vertical thickness="8"></v-divider>
        <v-btn v-if="profileStore.current" append-icon="mdi-logout" @click="profileStore.logout()">Se déconnecter</v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list nav dense>
        <v-list-item v-if="profileStore.current?.accountValidated" to="/" prepend-icon="mdi-home-city" title="Bâtiments / Etages"></v-list-item>
        <v-list-item v-if="profileStore.current?.accountValidated" to="/students" prepend-icon="mdi-account-multiple" title="Etudiants"></v-list-item>
        <v-list-item v-if="profileStore.current?.role == 'admin'" to="/admin/classnames" prepend-icon="mdi-book" title="Classes"></v-list-item>
        <v-list-item v-if="profileStore.current" to="/profile" prepend-icon="mdi-account-circle" title="Profil"></v-list-item>
        <v-list-item v-if="profileStore.current?.role == 'admin'" to="/admin/workers" prepend-icon="mdi-cogs" title="Administration"></v-list-item>
        <v-divider thickness="8"></v-divider>
        <v-list-item v-if="profileStore.current" @click="profileStore.logout()" append-icon="mdi-logout" title="Se déconnecter"></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <RouterView />
    </v-main>
      
    <UpdateAvailable @force-update="forceUpdate()"></UpdateAvailable>

    <v-snackbar v-model="snackbar" color="error" rounded="pill" :timeout="5000" elevation="24">
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false" icon="mdi-close"> </v-btn>
      </template>
    </v-snackbar>

    <div>
      <CookiesConsentBanner v-if="!cookiesStore.isAnswered" class="my-4"></CookiesConsentBanner>
      <v-row no-gutters class="mb-2 mx-8 footer">
        <v-label @click="router.push('/privacy')">Confidentialité</v-label>
        <v-spacer></v-spacer>
        <v-label>Version v{{ version }} - {{ dayjs(build_date).format("L") }}</v-label>
        <v-btn icon="mdi-information-outline" variant="text" size="small" @click="versionClicked = true"></v-btn>

        <UpdateAvailable v-if="versionClicked == true" force-display @forceUpdate="versionClicked = false"></UpdateAvailable>
      </v-row>
    </div>
  </v-app>
</template>
