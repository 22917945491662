<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref, type PropType, type Ref } from 'vue';
import { baseUrl } from '@/stores/baseurl';

const prop = defineProps({
  forceDisplay: { type: Boolean as PropType<Boolean>, default: false },
})

const version = ref(__APP_VERSION__)

const newVersionAvailable = ref(false)
const newVersion = ref("")

const changelog: Ref<Map<string, { description: string, features: Array<{ icon: string, text: string }>, fixes: Array<{ icon: string, text: string }> }>> = ref(new Map())
const changelogOrdered: Ref<Array<{ semver: string, value: { description: string, features: Array<{ icon: string, text: string }>, fixes: Array<{ icon: string, text: string }> } }>> = ref(new Array())
const currentVersionChangelog: Ref<{ description: string, features: Array<{ icon: string, text: string }>, fixes: Array<{ icon: string, text: string }> } | undefined> = ref()
const currentVersionChangelogOrderedIndex: Ref<number> = ref(0)

onMounted(async () => {
  // Check if version is aligned, otherwise reload and clear cache (using timestamp in URL)
  // Do it every hour !
  checkVersion()
  setInterval(() => {
    checkVersion()
  }, 60 * 60 * 1000)
})

async function checkVersion() {
  // query URL without using browser cache
  const headersNoCache = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
  const versionResp = await axios.get(`${baseUrl}/version`, {
    headers: headersNoCache,
  })
  const buildVersion = versionResp.data.version
  if (buildVersion !== version.value || prop.forceDisplay) {
    newVersionAvailable.value = true
    newVersion.value = buildVersion

    // Load changelog to display what's new in this version
    const changelogResp = await axios.get(`${baseUrl}/changelog`, {
      headers: headersNoCache,
    })
    changelog.value = new Map(Object.entries(changelogResp.data))
    changelog.value.forEach((value, key) => {
      changelogOrdered.value.push({ semver: key, value: value })
    })

    if (version.value == "0.0.0") {
      newVersion.value = changelog.value.keys().next().value ?? ""
    }
    currentVersionChangelog.value = changelog.value.get(newVersion.value)
    currentVersionChangelogOrderedIndex.value = changelogOrdered.value.findIndex(x => x.semver === newVersion.value)
  }
}

function containsPreviousVersion() {
  return currentVersionChangelogOrderedIndex.value < changelogOrdered.value.length - 1
}

function previousVersion() {
  if (containsPreviousVersion()) {
    currentVersionChangelogOrderedIndex.value++
    changedVersion()
  }
}

function containsNextVersion() {
  return currentVersionChangelogOrderedIndex.value > 0
}

function nextVersion() {
  if (containsNextVersion()) {
    currentVersionChangelogOrderedIndex.value--
    changedVersion()
  }
}

function changedVersion() {
  newVersion.value = changelogOrdered.value[currentVersionChangelogOrderedIndex.value].semver
  currentVersionChangelog.value = changelog.value.get(newVersion.value)
}

</script>
<template>
  <v-dialog v-model="newVersionAvailable" persistent>
    <v-row justify="center">
      <v-card style="width: min(500px, 80%); height: 400px;">
        <v-card-title>
          <div v-if="forceDisplay">Contenu de la version <b>{{ newVersion }}</b></div>
          <div v-if="!forceDisplay">
            <v-icon color="primary" class="mr-1">mdi-information</v-icon>
            Nouvelle version <b>{{ newVersion }}</b> disponible
          </div>
        </v-card-title>
        <v-col>
          <v-card-item style="display: inline-block; height: 272px; width: 100%; overflow: scroll;">
            <div class="mb-4"
              v-if="currentVersionChangelog?.features?.length && currentVersionChangelog?.features.length > 0">
              <v-divider class="mb-2"></v-divider>
              <v-card-subtitle>Nouvelles fonctionnalités</v-card-subtitle>
              <v-row v-for="(item, i) in currentVersionChangelog?.features" :key="i" :value="item" no-gutters
                justify="start">
                <div class="mr-1"><v-icon color="success">{{ item.icon }}</v-icon></div>
                <div style="width: calc(100% - 28px);">{{ item.text }}</div>
              </v-row>
            </div>
            <!-- Bug fixes -->
            <div v-if="currentVersionChangelog?.fixes.length && currentVersionChangelog?.fixes.length > 0">
              <v-divider class="mb-2"></v-divider>
              <v-card-subtitle>Correctifs</v-card-subtitle>
              <v-row v-for="(item, i) in currentVersionChangelog?.fixes" :key="i" :value="item" no-gutters>
                <div class="mr-1"><v-icon color="error">{{ item.icon }}</v-icon></div>
                <div style="width: calc(100% - 28px);">{{ item.text }}</div>
              </v-row>
            </div>
          </v-card-item>
          <v-card-actions>
            <v-col style="margin: 0; padding: 0;">
              <v-row no-gutters justify="center" style="width: 100%;">
                <v-btn class="mx-1" icon="mdi-arrow-left-drop-circle" variant="text" :disabled="!containsNextVersion()"
                  @click="nextVersion()"></v-btn>
                <v-btn class="mx-1" icon="mdi-arrow-right-drop-circle" variant="text"
                  :disabled="!containsPreviousVersion()" @click="previousVersion()"></v-btn>
              </v-row>
              <v-row no-gutters style="margin-top: -48px; height: 48px;" align="center">
                <v-spacer></v-spacer>
                <v-btn @click="$emit('forceUpdate')">Ok</v-btn>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-col>
      </v-card>
    </v-row>
  </v-dialog>
</template>