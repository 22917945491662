<script setup lang="ts">
import { getAttendanceDay } from "@/utils/dates";
import type dayjs from "dayjs";
import type { PropType } from "vue";
import { ref } from "vue";

defineProps({
  currentDay: { type: Object as PropType<dayjs.Dayjs> },
  isBottomPinable: { type: Boolean as PropType<Boolean> },
})
const emit = defineEmits(['change'])

const datePinned = ref(false)

const displayDatePicker = ref(false)
function changeDate(event: unknown) {
  let newDay = getAttendanceDay(event as dayjs.Dayjs)
  emit('change', newDay)
  displayDatePicker.value = false
}

function allowedDates(event: unknown) {
  let theDate = event as dayjs.Dayjs
  return theDate.day() >= 1 && theDate.day() <= 4
}

</script>

<style>
.date {
  z-index: 10;
}

.datePinned {
  text-align: center;
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%) translateY(calc(100vh - 64px)) translateZ(0);
}

.dateNotPinned {
  position: relative;
}
</style>

<template>
  <v-col align-content="center" class="date" :class="datePinned ? 'datePinned' : 'dateNotPinned'">
    <v-row no-gutters justify="center" align="center">
      <v-btn icon="mdi-arrow-left" :variant="datePinned ? 'elevated' : 'flat'"
        @click="changeDate(currentDay?.subtract(1, 'day'))"></v-btn>
      <v-menu v-model="displayDatePicker" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" style="width: 180px; border-radius: 20px;"
            :color="currentDay?.isToday() ? 'primary' : 'secondary'" class="text-center mx-2"
            :variant="datePinned ? 'elevated' : 'flat'">{{ currentDay?.format('dddd') }} {{
              currentDay?.format("DD/MM/YYYY") }}</v-btn>
        </template>
        <template v-slot:default="">
          <v-date-picker locale="fr-fr" :value="currentDay" @update:modelValue="(newDay) => changeDate(newDay)"
            color="primary" show-adjacent-months no-title
            :allowed-dates="(currentDate) => allowedDates(currentDate)"></v-date-picker>
        </template>
      </v-menu>
      <v-btn icon="mdi-arrow-right" :variant="datePinned ? 'elevated' : 'flat'"
        @click="changeDate(currentDay?.add(1, 'day'))"></v-btn>
      <v-btn v-if="isBottomPinable" class="ml-2" :icon="datePinned ? 'mdi-arrow-up' : 'mdi-arrow-down'" color="accent"
        variant="elevated" @click="datePinned = !datePinned"></v-btn>
    </v-row>
  </v-col>
</template>
