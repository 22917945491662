import { defineStore } from 'pinia'
import { baseUrl } from './baseurl'
import { ref, type Ref } from 'vue'
import { Worker } from './types/worker'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import dayjs from 'dayjs'
import { $ResetPinia } from './storecleaner'

const url = `${baseUrl}/workers`
const storageKey = 'JWT'

export const useProfileStore = defineStore('profile', () => {
  const email: Ref<string> = ref('')
  const password: Ref<string> = ref('')
  const passwordConfirmation: Ref<string> = ref('')
  const isExpired: Ref<boolean> = ref(false)
  const isValid: Ref<boolean> = ref(false)
  const current: Ref<Worker | undefined> = ref()

  async function getUser(): Promise<string | undefined> {
    const token = localStorage.getItem(storageKey)
    if (token == null) return
    try {
      // decode token here and attach to the user object
      const jwt = jwtDecode(token)
      email.value = jwt.sub as unknown as string
      isExpired.value = dayjs(dayjs()).isBefore(jwt.exp)
      const result = isExpired.value ? undefined : email.value
      isValid.value = !isExpired.value

      // set authorization header globally for all axios actions
      if (result != null)
        axios.defaults.headers.common['Authorization'] =
          `Bearer ${localStorage.getItem(storageKey)}`

      // if profile has not been retrieved yet, get it in order to know the user's role
      if (!current.value) {
        await getProfile()
      }
      return result
    } catch (error) {
      // return error in production env
      console.log(error, 'error from decoding token')
    }
  }

  async function login(): Promise<string | undefined> {
    $ResetPinia()
    localStorage.removeItem(storageKey)
    try {
      const resp = await axios.post(`${url}/login`, {
        email: email.value,
        password: password.value
      })
      localStorage.setItem(storageKey, JSON.stringify(resp.data.token))
      isValid.value = true
      getUser()
    } catch (err) {
      return 'Mauvaise adresse email ou mot de passe...'
    }
  }

  function logout() {
    $ResetPinia()
    localStorage.removeItem(storageKey)
    email.value = ''
    password.value = ''
    passwordConfirmation.value = ''
    isValid.value = false
    current.value = undefined
    //router.push("/login")
    window.location.href = '/login'
  }

  async function signup(): Promise<string | undefined> {
    console.log('Signup new user ' + email.value)
    try {
      if (password.value != passwordConfirmation.value) return
      await axios.post(`${url}/signup`, { email: email.value, password: password.value })
      isValid.value = true
    } catch (err) {
      return 'Adresse email déjà utilisée...'
    }
  }

  async function getProfile(): Promise<Worker | undefined> {
    try {
      const resp = await axios.get(`${url}/profile`)
      // Store user profile to be able to retrieve associated role from any page
      current.value = resp.data as Worker
      // remove class to hide recaptcha
      window.document.body.classList.add('recaptcha-hidden')
      return current.value
    } catch (err) {
      console.error('Failed to get profile', err)
      // restore display of recaptcha
      window.document.body.classList.remove('recaptcha-hidden')
    }
  }

  async function updateProfile(profile: Worker): Promise<Worker | undefined> {
    try {
      const resp = await axios.put(`${url}/profile`, profile)
      return resp.data as Worker
    } catch (err) {
      console.error('Failed to update profile', err)
    }
  }

  async function deleteProfile(): Promise<boolean> {
    try {
      await axios.delete(`${url}/profile`)
      logout()
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  async function updatePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    try {
      await axios.put(`${url}/profile/password`, { oldPassword, newPassword })
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  return {
    current,
    email,
    password,
    passwordConfirmation,
    isValid,
    login,
    logout,
    signup,
    getUser,
    getProfile,
    updateProfile,
    deleteProfile,
    updatePassword
  }
})
